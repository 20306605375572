<template>
  <div class="relative bg-brand overflow-hidden">
    <div class="relative">
      <MarketingNavigation variant="inverted" />

      <main class="mt-8 sm:mt-16 md:mt-20 lg:mt-24">
        <section class="mx-auto max-w-screen-xl">
          <div class="lg:grid lg:grid-cols-12 lg:gap-8 pb-20">
            <div class=" sm:w-full px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-5 lg:text-left lg:flex lg:items-center">
              <div>
                <h2 class="mt-4 text-3xl tracking-tight font-bold text-white leading-none sm:mt-5 sm:leading-tight sm:text-6xl lg:mt-6 lg:text-4xl xl:text-5xl sm:w-full ">
                  Fix Your Virtual
                  <br class="hidden sm:inline" />
                  Happy Hour
                </h2>
                <p class="mt-2 text-base text-gray-100 sm:mt-3 sm:text-xl lg:text-lg">
                  Teammates are auto-shuffled between different video chat rooms. 1 minute setup. Free.
                </p>
                <BaseButton
                  :link="true"
                  variant="inverted"
                  class="mt-6 sm:mt-13 lg:w-auto lg:inline-block"
                  href="/events/new"
                >
                  Try Now
                </BaseButton>
              </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-span-7 mx-auto w-11/12 lg:w-full lg:px-6">
              <div class="bg-white sm:w-full sm:mx-auto lg:rounded-lg sm:overflow-hidden card">
                <div class="px-4 py-2">
                  <div class="relative">
                    <img
                      src="../assets/images/demo-screenshot-fire.jpg"
                      alt="Screenshot of a roundsy round"
                    />
                    <div class="woodfire-tile">
                      <img
                        class="woodfire"
                        src="../assets/images/woodfire.gif"
                        alt="A nice toasty fire."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="text-center py-20 bg-white">
          <div class="mb-10">
            <h2 class="text-3xl mb-16 font-bold">Used by fun folks at</h2>
            <ul class="flex mx-auto max-w-screen-lg justify-around flex-col lg:flex-row text-center">
              <li>
                <img class="h-10 max-w-none mx-auto mb-24 lg:mb-0" src="../assets/images/logos/logo-dox.png" alt="doximity icon">
              </li>
              <li>
                <img class="h-10 max-w-none mx-auto mb-24 lg:mb-0" src="../assets/images/logos/logo-nw.png" alt="nerd wallet icon">
              </li>
              <li>
                <img class="h-10 max-w-none mx-auto" src="../assets/images/logos/logo-gh.png" alt="github icon">
              </li>
            </ul>
          </div>
        </section>

        <section class="py-20 bg-white">
          <div class="px-4 mb-20">
            <h2 class="text-3xl mb-3 font-bold text-center">How it works</h2>
          </div>
          <div class="max-w-screen-xl mx-auto flex flex-col">
            <div class="lg:grid lg:grid-cols-12 lg:gap-8 pb-20">
              <div class=" sm:w-full px-4 sm:px-6 text-left md:max-w-2xl md:mx-auto lg:col-span-5 lg:flex lg:items-center">
                <div class="text-center lg:text-left">
                  <p class="text-gray-400 font-bold">Step 1</p>
                  <h2 class="mt-2 tracking-tight font-bold leading-none sm:mt-5 sm:leading-tight text-2xl lg:mt-2 sm:w-full ">Create a Roundsy</h2>
                  <p class="mt-2 text-base sm:mt-3 sm:text-xl lg:text-lg">
                    In just a few clicks, set up the Roundsy to maximize your team’s fun.
                  </p>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-span-7 mx-auto w-11/12 lg:w-full lg:px-6">
                <div class="bg-white sm:w-full sm:mx-auto card">
                  <div class="p-8">
                    <DemoUiIcon class="mx-auto" />
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:grid lg:grid-cols-12 lg:gap-8 pt-20 pb-40 lg:pb-20">
              <div class=" sm:w-full px-4 sm:px-6 text-left md:max-w-2xl md:mx-auto lg:col-span-5 lg:flex lg:items-center order-last">
                <div class="text-center lg:text-left">
                  <p class="text-gray-400 font-bold">Step 2</p>
                  <h2 class="mt-2 tracking-tight font-bold leading-none sm:mt-5 sm:leading-tight text-2xl lg:mt-2 sm:w-full">Invite your team</h2>
                  <p class="mt-2 text-base sm:mt-3 sm:text-xl lg:text-lg">
                    Message, Slack, or email your team the link. They’ll join with just one click.
                  </p>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-span-7 mx-auto w-11/12 lg:w-full lg:px-6 order-first">
                <div class="bg-white sm:w-full sm:mx-auto px-8 py-2 md:max-w-lg lg:max-w-full">
                  <ChatBubbleIcon />
                </div>
              </div>
            </div>

            <div class="lg:grid lg:grid-cols-12 lg:gap-8 pb-20">
              <div class=" sm:w-full px-4 sm:px-6 text-left md:max-w-2xl md:mx-auto lg:col-span-5 lg:flex lg:items-center">
                <div class="text-center lg:text-left">
                  <p class="text-gray-400 font-bold">Step 3</p>
                  <h2 class="mt-2 tracking-tight font-bold leading-none sm:mt-5 sm:leading-tight text-2xl lg:mt-2 2m:w-full">Join the party and shuffle</h2>
                  <p class="mt-2 text-base sm:mt-3 sm:text-xl lg:text-lg">
                    Let the party begin!
                  </p>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-span-7 mx-auto w-11/12 lg:w-full lg:px-6">
                <div class="bg-white sm:w-full sm:mx-auto px-4 py-2">
                  <img src="../assets/images/group-bubble.jpg" alt="Group of people having fun." />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="text-center py-20 md:pb-40 bg-white fancy-bg">
          <div class="px-4 mb-20">
            <h2 class="text-3xl mb-3 font-bold">Virtual happy hours that are actually happy</h2>
            <p class="max-w-xl mx-auto mb-2">Roundsy makes virtual team gatherings fun by automagically sorting guests into small groups. (Because nobody likes a 50-person video happy hour)</p>
          </div>
          <div class="px-4 lg:grid lg:grid-cols-12 lg:gap-4 mb-6 lg:px-0">
            <img
              class="card w-full lg:col-start-4 lg:col-span-6 px-4 py-2"
              src="../assets/images/demo-screenshot-icebreaker.jpg"
              alt="Screenshot of a roundsy round"
            />
          </div>
          <div class="sm:w-full lg:grid lg:grid-cols-12 lg:gap-4">
            <div class="card lg:col-span-3 lg:col-start-4 px-6 py-10 mb-8 lg:mb-0 w-9/12 mx-auto lg:w-full">
              <div class="flex flex-col justify-center h-full">
                <h3 class="text-3xl mb-3 font-bold">Unlimited attendees,<br/>small group feel</h3>
                <p>Invite your team, whether it be 10 people or a 1000. You can cap each room between 2 and 5 guests.</p>
              </div>
            </div>
            <div class="card lg:col-span-3 lg:col-start-7 px-6 py-10 w-9/12 mx-auto lg:w-full">
              <div class="flex flex-col justify-center h-full">
                <h3 class="text-3xl mb-3 font-bold">Chit chat, then shuffle</h3>
                <p>Guests are shuffled every few minutes to meet new people and catch up with old friends</p>
              </div>
            </div>
          </div>
        </section>

        <section class="text-center py-20 bg-gray-100">
          <div class="mb-10">
            <h2 class="text-3xl mb-16 font-bold">Use Roundsy for</h2>
            <ul class="
              grid grid-flow-row gap-20
              sm:w-9/12 sm:mx-auto sm:grid-cols-2
              lg:grid-cols-3
              xl:max-w-7xl xl:w-full xl:mx-auto xl:grid-cols-5 xl:grid-rows-1">
              <li class="px-2 py-4 mx-auto w-9/12 lg:w-48 bg-white rounded text-center transform lg:rotate-6">
                <img class="w-20 h-20 mx-auto" src="../assets/images/icons/temp/icon-happy-hour.png" alt="icon">
                <p class="mt-10">Happy Hours</p>
              </li>
              <li class="px-2 py-4 mx-auto w-9/12 lg:w-48 bg-white rounded text-center transform lg:-rotate-6">
                <img class="w-20 h-20 mx-auto" src="../assets/images/icons/temp/icon-new-hire.png" alt="icon">
                <p class="mt-10">New Hire Mixers</p>
              </li>
              <li class="px-2 py-4 mx-auto w-9/12 lg:w-48 bg-white rounded text-center transform lg:rotate-6">
                <img class="w-20 h-20 mx-auto" src="../assets/images/icons/temp/icon-networking.png" alt="icon">
                <p class="mt-10">Networking Events</p>
              </li>
              <li class="px-2 py-4 mx-auto w-9/12 lg:w-48 bg-white rounded text-center transform lg:-rotate-6">
                <img class="w-20 h-20 mx-auto" src="../assets/images/icons/temp/icon-conference.png" alt="icon">
                <p class="mt-10">Conference Meetups</p>
              </li>
              <li class="px-2 py-4 mx-auto w-9/12 lg:w-48 bg-white rounded text-center transform lg:rotate-6">
                <img class="w-20 h-20 mx-auto" src="../assets/images/icons/temp/icon-party.png" alt="icon">
                <p class="mt-10">Holiday Parties</p>
              </li>
            </ul>
          </div>
        </section>
      </main>

      <footer class="bg-brand text-white text-center py-2">
        <p>&copy; Doximity 2021</p>
      </footer>
    </div>
  </div>
</template>

<script>
  import MarketingNavigation from '../components/MarketingNavigation.vue'
  import ChatBubbleIcon from '../assets/images/chat-bubble.svg'
  import DemoUiIcon from '../assets/images/demo-ui-icon.svg'

  export default {
    name: "Home",
    components: {
      MarketingNavigation,
      ChatBubbleIcon,
      DemoUiIcon,
    }
  }
</script>

<style>
.keep-me-hack {}
</style>
