import { Vue } from '../lib/globalVueSetup'
import Vuex from 'vuex'

import HomePage from '../vue/pages/home.vue'

document.addEventListener('DOMContentLoaded', () => {
  const vm = new Vue({
    render: (h) => h(HomePage)
  })

  const elm = document.querySelector('[data-init-target="home_index"]')
  vm.$mount(elm)
})
